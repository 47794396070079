import { defineStore } from 'pinia'

import type { User } from '../types'

type StoreState = {
  loaded: boolean
  me: User | undefined
}

export const useUserStore = defineStore('user', {
  state: (): StoreState => {
    return {
      loaded: false,
      me: undefined,
    }
  },
  actions: {
    setMe(me: User) {
      this.me = me
    },
  },
})
